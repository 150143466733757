<script>
import SwiperCore, {Thumbs, Navigation, Pagination} from "swiper";
// import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";


SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "./../layouts/main";
import appConfig from "../../app.config";

import {authComputed} from "@/state/helpers";
import moment from "moment/moment";
import ReferralService from "@/services/referral-service";

export default {
  page: {
    title: "Referral Program",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      thumbsSwiper: null,
      userRegistered: "",
      userSubscribed: "",
      userRegisteredPreviousMonth: 5,
      userSubscribedPreviousMonth: 0,
      rewards: [],
      subscribers: [],
    };
  },
  computed: {
    ...authComputed,
    link() {
      return "https://damask-trading.com/?code=" + this.currentUser.referral_code
    },
  },
  components: {
    Layout,
  },
  async beforeMount() {
    const rewardsResponse = await ReferralService.getRewards();
    if(rewardsResponse.status === 200) {
      this.rewards = rewardsResponse.data
    }

    const subscribers =  await ReferralService.subscribers();
    if(subscribers.status === 200) {
      console.log(subscribers.data);
      for (let subscriber of subscribers.data) {
        subscriber.show = true;
        this.subscribers.push(subscriber);
      }
    }
  },
  methods: {
    computePercentage(current, previous) {
      if (! previous && ! current) {
        return 0;
      }
      if (! previous) {
        return 100;
      }
      return Math.round((parseInt(parseFloat(current) / parseFloat(previous) * 100) / 100 - 1) * 100)
    },
    copy() {
      navigator.clipboard.writeText(this.link)
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD');
    },
  }
};
</script>
<style type="text/css">
li.disable {
  color: gray;
}

li.disable .avatar-title {
  background-color: #b8b9ba !important;
}
li.disable h5 {
  color:  #b8b9ba;
}
li .mdi-circle {
  color: forestgreen;
}
li.disable .mdi-circle {
  color: #b8b9ba;
}
li.disable .text-muted {
  color: #b8b9ba !important;
}
</style>
<template>
  <Layout>
    <div class="row">
        <div class="col-xl-6">
            <div class="card">
              <div class="card-body">
                <label class="form-label">{{ $t("t-referral-link") }}</label>
                <div class="input-group">
                  <input type="text" class="form-control disabled" disabled v-model="link"
                         aria-label="Your referral link">
                  <button class="btn btn-success" type="button" v-on:click="copy">{{ $t("t-copy") }}</button>
                </div>
              </div>
            </div>
          </div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-body">
              <label class="form-label">{{ $t("t-referral-balance-text") }}</label>
              <div class="input-group">
                <input type="text" class="form-control disabled" disabled :value="currentUser.reward_balance">
                <span class="input-group-text" id="basic-addon2">USD</span>
                <button type="submit" class="btn btn-primary" onclick="document.getElementById('error-msg').style='display:block;'" >{{ $t("t-referral-balance-payout-btn") }}</button>
                <div class="invalid-feedback" id="error-msg" >{{ $t("t-referral-balance-payout-error") }}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <div class="card-header align-items-center d-flex">
          <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-referral-my-subscribers") }} ({{subscribers.length}})</h4>
<!--          <div>-->
<!--            <button type="button" class="btn btn-soft-secondary btn-sm">-->
<!--              ALL-->
<!--            </button>-->
<!--            <button type="button" class="btn btn-soft-secondary btn-sm">-->
<!--              Active-->
<!--            </button>-->
<!--            <button type="button" class="btn btn-soft-secondary btn-sm">-->
<!--              Expired-->
<!--            </button>-->
<!--          </div>-->
        </div>
        <ul class="list-group">
          <li :class="subscriber.subscription_active === true ? 'list-group-item': 'list-group-item disable'" v-for="(subscriber, index) of subscribers" :key="index" :aria-disabled="subscriber.subscription_active">
            <div class="d-flex align-items-center">
              <div class="avatar-xs flex-shrink-0 me-3">
                <div class="avatar-title rounded-circle">
                  <i class="ri-user-2-fill"></i>
                </div>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-14 mb-0">{{ subscriber.username }}</h5>
                <p class="text-muted mb-0">{{ subscriber.email }}</p>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-14 text-center mb-0">{{ $t("t-referral-rank") }}:</h5>
<!--                <p class="text-muted text-center mb-0">{{ subscriber.rank }}</p>-->
                <p class="text-muted text-center mb-0">D</p>
              </div>
              <div class="flex-grow-1">
                <h5 class="fs-14 text-center mb-0">{{ $t("t-referral-users") }}:</h5>
                <p class="mb-0 text-muted text-center">{{ subscriber.users_count }}</p>
              </div>
              <div class="flex-grow-0">
                <i class="mdi mdi-circle"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </Layout>
</template>
